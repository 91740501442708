import claims from '@/shared/models/auth/claims'

export const orderType = [
  { text: 'Not Defined', value: 0 },
  { text: 'Direct Mail/Telemarketing', value: 1 },
  { text: 'Telemarketing', value: 2 },
  { text: 'Email Release', value: 3, claim: claims.MMS_ER },
  { text: 'Email Broadcast', value: 4, claim: claims.BS_TB },
  { text: 'Direct Mail', value: 5 }
]
