// This is NOT an executive source of file type data. That is stored in NOWW tables

export const fileType = [
  { id: 1, name: 'AMA', description: 'American Medical Association', color: '#311B92', dark: true },
  { id: 2, name: 'ADA', description: 'American Dental Association', color: '#01579B', dark: true },
  { id: 3, name: 'AADE', description: 'Association of Diabetes Care & Education Specialists', color: '#311b92', dark: true }, // TODO: update to ADCES when Ron is ready
  { id: 4, name: 'AAP', description: 'American Academy of Pediatrics', color: '#e1bee7', dark: false },
  { id: 5, name: 'ACP', description: 'American College of Physicians', color: '#a5d6a7', dark: false },
  { id: 6, name: 'ACCP', description: 'American College of Clinical Pharmacy', color: '#9fa8da', dark: false },
  { id: 10, name: 'AOA', description: 'American Osteopathic Association', color: '#1de9b6', dark: false },
  { id: 12, name: 'ASHP', description: 'American Society of Health System Pharmacists', color: '#0d47a1', dark: true },
  { id: 18, name: 'HOSP', description: 'Hospital Personnel and Executives', color: '#607D8B', dark: true },
  { id: 20, name: 'NH', description: 'Nursing Home Managers', color: '#3d5afe', dark: true },
  { id: 23, name: 'AAPA', description: 'American Academy of Physicians Assistants', color: '#FF7235', dark: true },
  { id: 26, name: 'CAN', description: 'Canadian Physicians', color: '#b388ff', dark: true },
  { id: 28, name: 'APN', description: 'Advanced Practice Nurses (Nurse Practitioners & CRNA CNM CNS)', color: '#004D40', dark: true },
  { id: 30, name: 'CHA', description: 'Chest Attendee', color: '#00695c', dark: true },
  { id: 31, name: 'AAPAC', description: 'AAPA Attendee', color: '#29b6f6', dark: false }
  // { id: 32, name: 'CSTM', description: 'Custom', color: '#ffffff', dark: false }
]

export const geoDataFileTypes = [
  ...fileType.filter(x => ['AADE' /* TODO: update to ADCES when Ron is ready */, 'AAP', 'AAPA',
  /* 'AAPAC', */ 'ADA', 'AMA', 'APN', /* 'CHA', */ 'HOSP'].includes(x.name))
]
